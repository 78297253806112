import {
  StateDefinition,
  StateIndex,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { SnapshotIndexClient } from '../storage/snapshot-index-client.js';
import { EventContextSnapshotIndexClient } from './runtime.js';
import { FilterValue } from '../storage/filter-value.js';
import { RangeOptions } from '../storage/range-options.js';
import { RangeResult } from '../storage/range-result.js';
import { FilterKey } from '../storage/filter-key.js';
import { FilterStateIndex } from '../storage/filter-state-index.js';

export function createEventContextIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
>(
  state: SnapshotIndexClient<TState, TIndex>,
  signal: AbortSignal,
): EventContextSnapshotIndexClient<TState, TIndex> {
  return {
    range(
      bookmark: FilterValue<TState, TIndex> | null,
      opts?: Omit<RangeOptions, 'signal'>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      return state.range(bookmark, {
        signal,
        open: opts?.open,
        dir: opts?.dir,
      });
    },
    filter(
      key: FilterKey<TIndex>,
      value: FilterValue<TState, TIndex>,
    ): EventContextSnapshotIndexClient<TState, FilterStateIndex<TIndex>> {
      return createEventContextIndexClient(state.filter(key, value), signal);
    },
  };
}
