import { StreamDefinition } from '../stream/stream-definition.js';
import { StoredEvent } from '../event-store/stored-event.js';
import { HostPushSuccessResult } from './runtime-result.js';
import { EventAuth } from '../event-store/event-auth.js';
import { TransactionOperation } from '../storage/transaction-operation.js';
import { assert } from '../typing/assert.js';
import { getStateData } from './get-state-data-keys.js';
import { getOrFail } from '../utils/get-or-fail.js';
import { OutputDeclarations } from '../stream/output-declarations.js';
import { fallbackAuth } from '../event-store/fallback-auth.js';

export function hostResultBuilder<
  TStream extends StreamDefinition<any, any, any, OutputDeclarations, any>,
>(stream: TStream, evt: StoredEvent) {
  const result: HostPushSuccessResult = {
    success: true,
    operation: [],
    outputs: [],
    permissions: [],
    auth: fallbackAuth(evt.auth),
    event: evt.event,
  };

  return {
    addOperation(operation: TransactionOperation) {
      result.operation.push(operation);
    },
    addPermissionChange(
      auth: EventAuth,
      type: 'grant_event' | 'revoke_event',
      permission: 'read' | 'write' | 'readwrite',
      eventTypes: string | string[],
    ) {
      if (typeof eventTypes === 'string') {
        result.permissions.push({
          type: type,
          event: eventTypes,
          permission,
          auth: {
            aud: auth.aud,
            iss: auth.iss,
            sub: auth.sub,
          },
        });
      } else {
        for (const eventType of eventTypes) {
          result.permissions.push({
            type: type,
            event: eventType,
            permission,
            auth: {
              aud: auth.aud,
              iss: auth.iss,
              sub: auth.sub,
            },
          });
        }
      }
    },
    addOutput<K extends string>(type: K, data: any) {
      const outputType = getOrFail(stream.outputs, type);
      assert(data, outputType.type);
      result.outputs.push({
        type,
        data: getStateData(outputType.type, data),
      });
    },
    get() {
      return result;
    },
  };
}
