import { StateDeclarations } from '../storage/state-declaration.js';
import { EventDeclarations } from '../event-store/event-declarations.js';
import { OutputDeclarations } from '../stream/output-declarations.js';
import { serializeObject } from '../utils/serialize-object.js';
import { sha1 } from '../authentication/sha1.js';
import { RuntimeEnv } from '../environment/runtime-env.js';
import { VersionBuilder } from '../runtime/version-builder.js';

export function generateVersion<
  TState extends StateDeclarations,
  TEvent extends EventDeclarations,
  TOutput extends OutputDeclarations,
>(state: TState, event: TEvent, output: TOutput): VersionBuilder {
  const versionString = serializeObject({
    state: Object.entries(state).map(([name, value]) => {
      return {
        name,
        schema: value.schema,
      };
    }),
    event: Object.entries(event).map(([name, value]) => {
      return {
        name,
        schema: value.schema,
      };
    }),
    output: Object.entries(output).map(([name, value]) => {
      return {
        name,
        schema: value.schema,
      };
    }),
  });
  let promise: Promise<string> | undefined = undefined;

  return {
    //source: versionString,
    resolve(env: RuntimeEnv): Promise<string> {
      if (!promise) {
        promise = sha1(env, versionString);
      }
      return promise;
    },
  };
}
