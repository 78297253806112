import { AuthProviders } from '../authentication/auth-providers.js';
import { RealmDefinition } from './realm-definition.js';

export interface RealmDefinitionOptions {
  providers: AuthProviders;
}

export function defineRealm<TOptions extends RealmDefinitionOptions>(
  name: string,
  options: TOptions,
): Readonly<RealmDefinition<TOptions['providers']>> {
  return {
    name: name,
    providers: options.providers,
  };
}
