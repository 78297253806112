import { StreamDefinition } from '../stream/stream-definition.js';
import { StoredEvent } from '../event-store/stored-event.js';
import { RuntimeResult } from './runtime-result.js';
import { EventAuth } from '../event-store/event-auth.js';
import {
  EventContextSnapshotClient,
  EventContextStorage,
  EventHandleContext,
  EventHandleStateClient,
} from './runtime.js';
import { StreamHostRuntime } from './host-runtime.js';
import { EventRandomSeed } from '../event-store/event-random-seed.js';
import { getErrorMessage, getErrorStackTrace } from './get-error-message.js';
import { hostResultBuilder } from './host-result-builder.js';
import { RuntimeEnv } from '../environment/runtime-env.js';
import {
  StateDefinitions,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { RealmDefinition } from '../realm/realm-definition.js';
import { AuthProviders } from '../authentication/auth-providers.js';
import { UpsertType } from '../storage/upsert-type.js';
import { PatchType } from '../storage/patch-type.js';
import { DeleteType } from '../storage/delete-type.js';
import { createEventContextStorage } from './create-event-context-storage.js';
import { EventDeclarations } from '../event-store/event-declarations.js';
import { HandleDeclarations } from '../stream/handle-declarations.js';
import { OutputDeclarations } from '../stream/output-declarations.js';
import { getOrFail } from '../utils/get-or-fail.js';

export function createStreamHost<
  TState extends StateDefinitions,
  TEvent extends EventDeclarations,
  TOutput extends OutputDeclarations,
  TRealm extends RealmDefinition<AuthProviders>,
>(
  stream: StreamDefinition<
    TState,
    TEvent,
    HandleDeclarations<TState, TEvent, TOutput>,
    TOutput,
    TRealm
  >,
): StreamHostRuntime<TState, TEvent, TOutput, TRealm> {
  return {
    source: stream,
    runtime: {
      async execute(
        name: string,
        args: Record<string, string>,
        state: EventContextStorage<TState>,
        evt: StoredEvent,
        random: EventRandomSeed,
        env: RuntimeEnv,
        signal: AbortSignal,
      ): Promise<RuntimeResult> {
        try {
          const resultBuilder = hostResultBuilder(stream, evt);

          const ctx: EventHandleContext<any, any, any> = {
            env,
            signal,
            stream: {
              name,
              pattern: stream.pattern,
              args,
              allowRead(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'read',
                  eventTypes,
                );
              },
              allowReadWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'readwrite',
                  eventTypes,
                );
              },
              allowWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'write',
                  eventTypes,
                );
              },
              revokeRead(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'read',
                  eventTypes,
                );
              },
              revokeReadWrite(
                authId: EventAuth,
                eventTypes: string[] | string,
              ) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'readwrite',
                  eventTypes,
                );
              },
              revokeWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'write',
                  eventTypes,
                );
              },
            },
            emit<K extends string>(type: K, data: any): void {
              resultBuilder.addOutput(type, data);
            },
            state<K extends string>(
              name: K,
            ): EventHandleStateClient<TState[K]> {
              return {
                async delete(id: DeleteType<TState[K]>): Promise<void> {
                  const { keys } = await state.state(name).delete(id);
                  resultBuilder.addOperation({
                    type: 'delete',
                    state: name,
                    keys,
                  });
                },
                async patch(val: PatchType<TState[K]>): Promise<void> {
                  const { data, keys } = await state.state(name).patch(val);
                  resultBuilder.addOperation({
                    type: 'patch',
                    state: name,
                    data,
                    keys,
                  });
                },
                async insert(
                  val: TypeOfStateDeclaration<TState[K]>,
                ): Promise<void> {
                  const { data, keys } = await state.state(name).insert(val);
                  resultBuilder.addOperation({
                    type: 'insert',
                    state: name,
                    data,
                    keys,
                  });
                },
                async upsert(val: UpsertType<TState[K]>): Promise<void> {
                  const { data, keys } = await state.state(name).upsert(val);
                  resultBuilder.addOperation({
                    type: 'upsert',
                    state: name,
                    data,
                    keys,
                  });
                },
              };
            },
            snapshot<K extends string & keyof TState>(
              name: K,
            ): EventContextSnapshotClient<TState[K]> {
              return createEventContextStorage(state.snapshot(name), signal);
            },
            random,
            metadata: {
              id: evt.id,
              auth: evt.auth,
              createdAt: evt.createdAt,
              storedAt: evt.storedAt,
              annotations: evt.annotations,
            },
          };

          const handle = getOrFail(stream.handle, evt.event);

          await handle(evt.data, ctx);

          return resultBuilder.get();
        } catch (e) {
          return {
            success: false,
            error: getErrorMessage(e),
            stacktrace: getErrorStackTrace(e),
          };
        }
      },
    },
  };
}
