import { StateDefinitions } from '@aion/core/storage/state-declaration.js';
import { EventDeclarations } from '@aion/core/event-store/event-declarations.js';
import { StreamHostRuntime } from '@aion/core/runtime/host-runtime.js';
import { VersionBuilder } from '@aion/core/runtime/version-builder.js';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';
import { AuthProviders } from '@aion/core/authentication/auth-providers.js';
import { OutputDeclarations } from '@aion/core/stream/output-declarations.js';

export interface RemoteStream<
  TState extends StateDefinitions,
  TEvent extends EventDeclarations,
> {
  state: TState;
  events: TEvent;

  version: VersionBuilder;
  realm: string;
  pattern: string;
}

export function createRemoteStream<
  TState extends StateDefinitions,
  TEvent extends EventDeclarations,
  TRealm extends RealmDefinition<AuthProviders>,
>(stream: StreamHostRuntime<TState, TEvent, OutputDeclarations, TRealm>) {
  return {
    version: stream.source.version,
    events: stream.source.events,
    state: stream.source.state,
    pattern: stream.source.pattern,
    realm: stream.source.realm.name,
  };
}
