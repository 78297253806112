import { StreamDefinition } from '../stream/stream-definition.js';
import { RealmDefinition } from '../realm/realm-definition.js';
import { AuthProviders } from '../authentication/auth-providers.js';
import { InjectedEvent } from './injected-event.js';

export function injectEvent<
  TStream extends StreamDefinition<any, any, any, any, TRealm>,
  TRealm extends RealmDefinition<AuthProviders>,
  TEventKey extends keyof TStream['events'] & string,
>(
  stream: { source: TStream },
  key: TEventKey,
): InjectedEvent<TStream['events'][TEventKey], TRealm> {
  return {
    stream: { version: stream.source.version, pattern: stream.source.pattern },
    type: stream.source.events[key],
    event: key,
    realm: stream.source.realm,
  };
}
