import { AggregationDefinition } from './aggregation-definition.js';
import { StateDeclarations } from '../storage/state-declaration.js';
import { AuthProviders } from '../authentication/auth-providers.js';
import { RealmDefinition } from '../realm/realm-definition.js';
import { createAggregateHost } from '../runtime/create-aggregate-host.js';
import { generateVersion } from '../realm/generate-version.js';
import { EventDeclaration } from '../event-store/event-declarations.js';
import { AggregationHandleDeclarations } from './aggregation-handle-declarations.js';
import { AggregationOptions } from './aggregation-options.js';
import { AggregationEventDeclaration } from './aggregation-event-declaration.js';

export function defineAggregation<
  TState extends StateDeclarations,
  TEvent extends AggregationEventDeclaration<TRealm>,
  THandle extends AggregationHandleDeclarations<TState, TEvent, TRealm>,
  TRealm extends RealmDefinition<AuthProviders>,
>(realm: TRealm, opts: AggregationOptions<TState, TEvent, THandle, TRealm>) {
  return createAggregateHost(<
    AggregationDefinition<TState, TEvent, THandle, TRealm>
  >{
    realm: realm,
    version: generateVersion(
      opts.state,
      // TODO verify if injection info needs to be in version
      Object.entries(opts.events).reduce<{ [key: string]: EventDeclaration }>(
        (map, [key, injected]) => {
          map[key] = injected.type;
          return map;
        },
        {},
      ),
      {},
    ),
    pattern: opts.pattern,
    state: opts.state,
    handle: opts.handle,
    events: opts.events,
    auth: opts.auth,
  });
}
