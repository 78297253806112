import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { groupStream } from '../../state/group/stream';
import { timedSignal } from '@aion/core/utils/timedSignal.js';
import { ApiService } from '../../../../common/src/lib/api.service.js';
import { createRemoteStream } from '@aion/client/management/remote-stream.js';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-group-create-modal',
  templateUrl: './group-create-modal.component.html',
  styleUrls: ['./group-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule],
})
export class GroupCreateModalComponent {
  private router = inject(Router);
  private api = inject(ApiService);

  name?: string;

  dismiss() {
    this.router.navigate([{ outlets: { modal: [] } }], {});
  }

  async create() {
    if (!this.name) {
      return;
    }

    const groupId = Math.random().toString(36);
    const stream = this.api.remote.getStream(createRemoteStream(groupStream), {
      args: {
        groupId: groupId,
      },
    });
    await stream.push(
      'create-group',
      { name: this.name },
      { signal: timedSignal() },
    );
    await this.router.navigate([{ outlets: { modal: [] } }], {});
    await this.router.navigate(['/', groupId]);
  }
}
