import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { personalGroupAggregation } from '../../state/aggregation/personal-groups';
import {
  fromAsyncIterable,
  signalFromDestroyRef,
} from '../../../../common/src/lib/signal-from-destroy-ref.js';
import { shareReplay } from 'rxjs';
import { ApiService } from '../../../../common/src/lib/api.service.js';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-todo-sidebar',
  templateUrl: './todo-sidebar.component.html',
  styleUrls: ['./todo-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, AsyncPipe, RouterLinkActive],
})
export class TodoSidebarComponent {
  router = inject(Router);
  destroyRef = inject(DestroyRef);
  api = inject(ApiService);

  groupAggregation = this.api.remote.getAggregation(personalGroupAggregation, {
    args: {},
  });

  groups$ = fromAsyncIterable((signal) =>
    this.groupAggregation.observe('groups').index('name').observeRange(null, {
      take: 10,
      dir: 'prev',
      signal,
    }),
  ).pipe(shareReplay(1));

  async createGroup() {
    await this.router.navigate([{ outlets: { modal: ['create-group'] } }], {});
  }
}
