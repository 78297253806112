import { Routes } from '@angular/router';
import { TodoPageComponent } from './todo-page/todo-page.component';
import { GroupCreateModalComponent } from './group-create-modal/group-create-modal.component';
import { loginGuard } from '../../../common/src/lib/auth/login.guard.js';

export const routes: Routes = [
  {
    path: ':group',
    component: TodoPageComponent,
    canActivate: [loginGuard],
  },
  {
    outlet: 'modal',
    path: 'create-group',
    component: GroupCreateModalComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('../../../common/src/lib/auth/auth.routes.js').then(
        (r) => r.routes,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'personal',
  },
];
