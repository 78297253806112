import { groupEvents } from './events.js';
import { groupState } from './state.js';
import { groupOutputs } from './outputs.js';
import { todoRealm } from '../realm.js';
import { createGroup } from './create-group/create-group.js';
import { inviteUser } from './invite-user/invite-user.js';
import { acceptInvite } from './accept-invite/accept-invite.js';
import { defineStream } from '@aion/core/stream/define-stream.js';
import { createTodo } from '../todo/create-todo/create-todo.js';
import { updateTodo } from '../todo/update-todo/update-todo.js';
import { completeTodo } from '../todo/complete-todo/complete-todo.js';
import { incompleteTodo } from '../todo/incomplete-todo/incomplete-todo.js';

export const groupStream = defineStream(todoRealm, {
  events: groupEvents,
  state: groupState,
  outputs: groupOutputs,
  pattern: 'group-$(args.groupId)',
  auth: { allowAnonymous: false },
  handle: {
    'create-todo': (evt, ctx) => createTodo(evt, ctx as any),
    'update-todo': (evt, ctx) => updateTodo(evt, ctx as any),
    'complete-todo': (evt, ctx) => completeTodo(evt, ctx as any),
    'incomplete-todo': (evt, ctx) => incompleteTodo(evt, ctx as any),
    'create-group': createGroup,
    'invite-user': inviteUser,
    'accept-invite': acceptInvite,
  },
});
