import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '../api.service.js';

export const loginGuard: CanActivateFn = async (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  const auth = await apiService.remote.session.resolveAuth();
  if (auth.type === 'unauthenticated') {
    console.log('redirect to login');
    return router.createUrlTree(['/auth/login']);
  } else {
    return true;
  }
};
