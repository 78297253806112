import { todoStream } from '../todo/stream';
import { todoRealm } from '../realm.js';
import { string } from '@aion/core/typing/string.js';
import { boolean } from '@aion/core/typing/boolean.js';
import { date } from '@aion/core/typing/date.js';
import { declareState } from '@aion/core/storage/declare-state.js';
import { injectEvent } from '@aion/core/aggregation/inject-event.js';
import { defineAggregation } from '@aion/core/aggregation/define-aggregation.js';
import { groupStream } from '../group/stream.js';
import { defineState } from '@aion/core/storage/define-state.js';

export const Todo = declareState(
  {
    id: string(),
    stream: string(),
    title: string(),
    completed: boolean(),
    createdAt: date(),
  },
  ['stream', 'id'],
).index('date', ['createdAt']);

export const aggState = defineState({
  todos: Todo,
});

export const personalTodoAggregation = defineAggregation(todoRealm, {
  pattern: 'personal-$(auth.sub)',
  state: aggState,
  auth: { allowAnonymous: false },
  events: {
    'create-todo': injectEvent(todoStream, 'create-todo'),
    'update-todo': injectEvent(todoStream, 'update-todo'),
    'complete-todo': injectEvent(todoStream, 'complete-todo'),
    'incomplete-todo': injectEvent(todoStream, 'incomplete-todo'),
    'group-create-todo': injectEvent(groupStream, 'create-todo'),
    'group-update-todo': injectEvent(groupStream, 'update-todo'),
    'group-complete-todo': injectEvent(groupStream, 'complete-todo'),
    'group-incomplete-todo': injectEvent(groupStream, 'incomplete-todo'),
  },
  handle: {
    'create-todo': async (evt, ctx) => {
      await ctx.state('todos').insert({
        createdAt: new Date(),
        title: evt.title,
        completed: false,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'group-create-todo': async (evt, ctx) => {
      await ctx.state('todos').insert({
        createdAt: new Date(),
        title: evt.title,
        completed: false,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'update-todo': async (evt, ctx) => {
      await ctx.state('todos').patch({
        title: evt.title,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'group-update-todo': async (evt, ctx) => {
      await ctx.state('todos').patch({
        title: evt.title,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'complete-todo': async (evt, ctx) => {
      await ctx.state('todos').patch({
        completed: true,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'group-complete-todo': async (evt, ctx) => {
      await ctx.state('todos').patch({
        completed: true,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'incomplete-todo': async (evt, ctx) => {
      await ctx.state('todos').patch({
        completed: true,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
    'group-incomplete-todo': async (evt, ctx) => {
      await ctx.state('todos').patch({
        completed: true,
        stream: ctx.stream.name,
        id: ctx.metadata.id,
      });
    },
  },
});
