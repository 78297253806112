import { OpenIDProvider } from '../open-id-provider.js';
import { Secret } from '../../realm/secret.js';
import { jwtStore, JwtStoreOptions } from '../jwt-store.js';

export interface GoogleOAuthOptions {
  clientId: string;
  clientSecret: Secret;
  scope?: string;
  tokenAttributes?: string[];
  jwt?: Partial<JwtStoreOptions>;
}

export function createGoogleOAuth(
  opts: GoogleOAuthOptions,
): Readonly<OpenIDProvider> {
  return {
    type: 'openid',
    clientId: opts.clientId,
    clientSecret: opts.clientSecret,

    audience: undefined,
    issuer: 'https://accounts.google.com',

    // openIdConfigurationUrl: 'https://accounts.google.com/.well-known/openid-configuration',
    token: {
      url: 'https://oauth2.googleapis.com/token',
      formData: {
        tenant: 'common',
      },
      tokenAttributes: opts.tokenAttributes ?? [
        'picture',
        'given_name',
        'family_name',
        'email',
        'name',
      ],
    },

    deviceCode: {
      codeUrl: 'https://oauth2.googleapis.com/device/code',
      grantType: 'urn:ietf:params:oauth:grant-type:device_code',
      verificationUriName: 'verification_url',
      clientSecret: true,
      scope: 'openid',
    },

    jwks: {
      url: 'https://www.googleapis.com/oauth2/v3/certs',
      subKey: 'sub',
    },

    authorize: {
      url: 'https://accounts.google.com/o/oauth2/v2/auth',
      queryParams: {
        client_id: opts.clientId,
        response_type: 'code',
        access_type: 'offline',
        scope: opts.scope ?? 'openid email profile',
      },
    },

    jwt: jwtStore({
      algorithm: opts.jwt?.algorithm ?? 'HS256',
      issuer: opts.jwt?.issuer ?? 'google',
      audience: opts.jwt?.audience ?? opts.clientId,
      secret: opts.jwt?.secret ?? opts.clientSecret,
    }),
  };
}
