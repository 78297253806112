import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { UnauthenticatedSession } from '@aion/client/oauth/unauthenticated-session.js';
import { AuthenticatedSession } from '@aion/client/oauth/authenticated-session.js';
import { Router, RouterLink } from '@angular/router';
import { signalFromDestroyRef } from '../../signal-from-destroy-ref.js';
import { ApiService } from '../../api.service.js';
import { AuthConfig } from '../../provide-api.js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink],
})
export class NavbarComponent implements OnInit {
  api = inject(ApiService);
  router = inject(Router);
  destroyRef = inject(DestroyRef);
  signal = signalFromDestroyRef(this.destroyRef);
  authConfig = inject(AuthConfig);

  session = signal<AuthenticatedSession | UnauthenticatedSession>({
    type: 'unauthenticated',
  });

  ngOnInit() {
    this.api.remote.session.on((evt) => {
      if (evt.type === 'authenticated' || evt.type === 'unauthenticated') {
        this.session.set(evt);
      }
    }, this.signal);
  }

  async logout() {
    await this.api.remote.session.logout();
    await this.router.navigate(['/login']);
  }
}
