import { OpenIDProvider } from '../open-id-provider.js';
import { Secret } from '../../realm/secret.js';
import { jwtStore, JwtStoreOptions } from '../jwt-store.js';

export interface MicrosoftOAuthOptions {
  clientId: string;
  clientSecret: Secret;
  tenant: string; // 'common' | 'organizations' | 'consumers'
  scope?: string;
  tokenAttributes?: string[];
  jwt?: Partial<JwtStoreOptions>;
}

export function createMicrosoftOAuth(
  opts: MicrosoftOAuthOptions,
): Readonly<OpenIDProvider> {
  return {
    type: 'openid',
    clientId: opts.clientId,
    clientSecret: opts.clientSecret,

    audience: undefined,
    issuer:
      'https://login.microsoftonline.com/9188040d-6c67-4c5b-b112-36a304b66dad/v2.0',

    //openIdConfigurationUrl: 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration',
    jwks: {
      url: `https://login.microsoftonline.com/${opts.tenant}/discovery/v2.0/keys`,
      subKey: 'sub',
    },

    token: {
      url: `https://login.microsoftonline.com/${opts.tenant}/oauth2/v2.0/token`,
      formData: {},
      tokenAttributes: opts.tokenAttributes ?? [
        'tid',
        'oid',
        'preferred_username',
        'email',
        'name',
      ],
    },

    deviceCode: {
      codeUrl: `https://login.microsoftonline.com/${opts.tenant}/oauth2/v2.0/devicecode`,
      grantType: 'urn:ietf:params:oauth:grant-type:device_code',
      verificationUriName: 'verification_uri',
      clientSecret: false,
      scope: opts.scope ?? 'openid email offline_access profile',
    },

    authorize: {
      url: `https://login.microsoftonline.com/${opts.tenant}/oauth2/v2.0/authorize`,
      queryParams: {
        client_id: opts.clientId,
        tenant: opts.tenant,
        scope: opts.scope ?? 'openid email offline_access profile',
        response_type: 'code',
      },
    },

    jwt: jwtStore({
      algorithm: opts.jwt?.algorithm ?? 'HS256',
      issuer: opts.jwt?.issuer ?? 'microsoft',
      audience: opts.jwt?.audience ?? opts.tenant,
      secret: opts.jwt?.secret ?? opts.clientSecret,
    }),
  };
}
