import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { Todo } from '../../state/todo/state';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-todo-list-item',
  templateUrl: './todo-list-item.component.html',
  styleUrls: ['./todo-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class TodoListItemComponent {
  todo = input.required<Todo>();

  checkedChanged = output<{ todo: Todo; checked: boolean }>();

  async updateCompletion(todo: Todo, evt: Event) {
    if (!evt.target) {
      return;
    }
    const target = evt.target;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }
    this.checkedChanged.emit({ todo, checked: target.checked });
  }
}
