import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
  provideIwentsourcingApi,
  provideIwentsourcingAuth,
} from '../../../common/src/lib/provide-api.js';
import { routes } from './app.routes.js';
import { todoRealm } from '../state/realm.js';
import { environment } from '../environments/environment.js';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
    ),
    provideIwentsourcingApi({
      host: environment.host,
      tenant: environment.tenant,
      realm: todoRealm,
    }),
    provideIwentsourcingAuth({
      unauthorizedUrl: '/',
      authorizedUrl: '/personal',
      logoUrl: '/icon200h.png',
    }),
  ],
};
