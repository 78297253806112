import { AggregationDefinition } from '../aggregation/aggregation-definition.js';
import { AggregateHostRuntime } from './host-runtime.js';
import { StateDeclarations } from '../storage/state-declaration.js';
import { defineStream } from '../stream/define-stream.js';
import { getOrCreate } from '../utils/get-or-create.js';
import { transformMap } from '../utils/transform-map.js';
import { EventSource } from './event-source.js';
import { RealmDefinition } from '../realm/realm-definition.js';
import { AuthProviders } from '../authentication/auth-providers.js';
import { AggregationEventDeclaration } from '../aggregation/aggregation-event-declaration.js';

export function createAggregateHost<
  TState extends StateDeclarations,
  TEvent extends AggregationEventDeclaration<any>,
  TRealm extends RealmDefinition<AuthProviders>,
>(
  agg: AggregationDefinition<TState, TEvent, any, TRealm>,
): AggregateHostRuntime<TState, TRealm> {
  const streamSources = Object.entries(agg.events).reduce<{
    [key: string]: EventSource;
  }>((map, [eventKey, handle]) => {
    const value = getOrCreate<EventSource>(
      map,
      `${handle.stream.pattern}`,
      () => ({
        stream: handle.stream,
        handles: {},
        events: {},
      }),
    );

    value.handles[handle.event] = agg.handle[eventKey];
    value.events[handle.event] = {
      type: handle.type.type,
      schema: handle.type.schema,
    };

    return map;
  }, {});

  return {
    source: agg,
    runtimes: transformMap(streamSources, (source) =>
      defineStream(agg.realm, {
        pattern: source.stream.pattern,
        state: agg.state,
        events: source.events,
        handle: source.handles,
        outputs: {},
        auth: { allowAnonymous: false },
      }),
    ),
  };
}
