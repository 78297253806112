import { jwtStore } from '@aion/core/authentication/jwt-store.js';
import { secret } from '@aion/core/realm/secret.js';
import { createGoogleOAuth } from '@aion/core/authentication/providers/google.js';
import { createMicrosoftOAuth } from '@aion/core/authentication/providers/microsoft.js';
import { defineRealm } from '@aion/core/realm/define-realm.js';

export const todoRealm = defineRealm('todo', {
  providers: {
    custom: jwtStore({
      issuer: 'custom',
      secret: '1234',
      audience: 'custom',
    }),
    microsoft: createMicrosoftOAuth({
      clientId: 'b7cd7686-6e5c-4224-a1d3-b974f00635c7',
      clientSecret: secret('MICROSOFT_CLIENT_SECRET'),
      tenant: 'common',
    }),
    google: createGoogleOAuth({
      clientId:
        '520005346035-rbe6toslf223ks3cocktgfvqaurcg4dq.apps.googleusercontent.com',
      clientSecret: secret('GOOGLE_CLIENT_SECRET'),
    }),
  },
});
