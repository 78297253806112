import { StateDeclarations } from '../storage/state-declaration.js';
import { EventDeclarations } from '../event-store/event-declarations.js';
import { OutputDeclarations } from './output-declarations.js';
import { RealmDefinition } from '../realm/realm-definition.js';
import { AuthProviders } from '../authentication/auth-providers.js';
import { HandleDeclarations } from './handle-declarations.js';
import { StreamOptions } from './stream-options.js';
import { createStreamHost } from '../runtime/create-stream-host.js';
import { generateVersion } from '../realm/generate-version.js';
import { StreamHostRuntime } from '../runtime/host-runtime.js';

export function defineStream<
  TState extends StateDeclarations,
  TEvent extends EventDeclarations,
  THandle extends HandleDeclarations<TState, TEvent, TOutput>,
  TOutput extends OutputDeclarations,
  TRealm extends RealmDefinition<AuthProviders>,
>(
  realm: TRealm,
  opts: StreamOptions<TState, TEvent, THandle, TOutput>,
): StreamHostRuntime<TState, TEvent, TOutput, TRealm> {
  return createStreamHost({
    realm: realm,
    version: generateVersion(opts.state, opts.events, opts.outputs),
    outputs: opts.outputs,
    state: opts.state,
    events: opts.events,
    pattern: opts.pattern,
    handle: opts.handle,
    auth: opts.auth,
  });
}
