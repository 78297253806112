import { CompleteTodoEvent } from './complete-todo/complete-todo.event';
import { IncompleteTodoEvent } from './incomplete-todo/incomplete-todo.event';
import { CreateTodoEvent } from './create-todo/create-todo.event';
import { UpdateTodoEvent } from './update-todo/update-todo.event';

import { defineEvents } from '@aion/core/stream/define-events.js';

export const todoEvents = defineEvents({
  'create-todo': CreateTodoEvent,
  'complete-todo': CompleteTodoEvent,
  'incomplete-todo': IncompleteTodoEvent,
  'update-todo': UpdateTodoEvent,
} as const);

export type TodoEvents = typeof todoEvents;
