import { GroupContext } from '../context.js';
import { CreateGroupEvent } from './create-group.event.js';
import { getOrFail } from '@aion/core/utils/get-or-fail.js';

export function createGroup(evt: CreateGroupEvent, ctx: GroupContext) {
  if (evt.name.length === 0 || !ctx.metadata.auth) {
    return;
  }
  ctx.state('group').insert({
    id: getOrFail(ctx.stream.args, 'groupId'),
    name: evt.name,
    createdAt: new Date(),
  });

  ctx.state('groupMembers').insert({
    id: ctx.metadata.id,
    //name: ctx.metadata.auth.name,
    name: undefined,
    auth: ctx.metadata.auth,
    state: 'accepted',
    email: undefined,
    //email: ctx.metadata.auth.email,
    createdAt: ctx.metadata.createdAt,
  });
  ctx.stream.allowWrite(ctx.metadata.auth, ['invite-user']);
}
