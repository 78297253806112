import {
  StateDefinition,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { SnapshotClient } from '../storage/snapshot-client.js';
import {
  EventContextSnapshotClient,
  EventContextSnapshotIndexClient,
} from './runtime.js';
import { RangeResult } from '../storage/range-result.js';
import { RangeOptions } from '../storage/range-options.js';
import { createEventContextIndexClient } from './create-event-context-index-client.js';

export function createEventContextStorage<
  TState extends StateDefinition<any, any, any>,
>(
  state: SnapshotClient<TState>,
  signal: AbortSignal,
): EventContextSnapshotClient<TState> {
  return {
    get(
      id: StateKeyValues<TState>,
    ): Promise<RangeResult<TypeOfStateDeclaration<TState>> | null> {
      return state.get(id, { signal });
    },
    range(
      bookmark: StateKeyValues<TState> | null,
      opts?: Omit<RangeOptions, 'signal'>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      return state.range(bookmark, {
        open: opts?.open,
        dir: opts?.dir,
        signal,
      });
    },
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): EventContextSnapshotIndexClient<TState, TState['indices'][K]> {
      const indexState = state.index(index);
      return createEventContextIndexClient(indexState, signal);
    },
  };
}
